<template>
  <div class="mt-4">
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-6/12 w-full">
        <label class="ml-1 text-xs">No. STC</label>
        <vs-input class="w-full" :value="initData.header.no_stc" readonly/>
      </div>
      <div class="vx-col sm:w-6/12 w-full">
        <label class="ml-1 text-xs">Tgl STC</label>
        <vs-input class="w-full" :value="initData.header.tgl_stc" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-6/12 w-full">
        <label class="ml-1 text-xs">No. AJB</label>
        <vs-input class="w-full" :value="initData.header.no_ajb" readonly/>
      </div>
      <div class="vx-col sm:w-6/12 w-full">
        <label class="ml-1 text-xs">Tgl AJB</label>
        <vs-input class="w-full" :value="initData.header.tgl_ajb" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">No. Kontrak</label>
        <vs-input class="w-full" :value="initData.header.no_kontrak" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">No. Unit</label>
        <vs-input class="w-full" :value="initData.header.no_unit" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Nama Customer</label>
        <vs-input class="w-full" :value="initData.header.nama_customer" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">No. Garansi</label>
        <vs-input class="w-full" :value="initData.header.no_garansi" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Tgl Habis Garansi</label>
        <vs-input class="w-full" :value="initData.header.tgl_habis_garansi" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Masa Garansi (hari)</label>
        <vs-input class="w-full" :value="initData.header.masa_garansi" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-6/12 w-full">
        <label class="ml-1 text-xs">Foto</label>
        <div @click="showImages(initData.header.foto_url)">
          <vx-input-group>
            <vs-input v-if="initData.header.foto_url" :value="`${initData.header.foto_url.length} Foto`" readonly/>
            <template slot="append">
              <div class="append-text btn-addon">
                <vs-button type="filled" icon-pack="feather" icon="icon-image">Lihat</vs-button>
              </div>
            </template>
          </vx-input-group>
        </div>
      </div>
      <div class="vx-col sm:w-6/12 w-full">
        <label class="ml-1 text-xs">File</label>
        <div @click="showFiles(initData.header.files_url)">
          <vx-input-group>
            <vs-input v-if="initData.header.files_url" :value="`${initData.header.files_url.length} File`" readonly/>
            <template slot="append">
              <div class="append-text btn-addon">
                <vs-button type="filled" icon-pack="feather" icon="icon-file">Lihat</vs-button>
              </div>
            </template>
          </vx-input-group>
        </div>
      </div>
    </div>
    <div class="vx-row float-right mt-5">
      <div class="vx-col w-full flex">
        <vs-button @click="next" icon-pack="feather" icon="icon-arrow-right" icon-after>Next</vs-button>
      </div>
    </div>

    <!--modals-->
    <FilesViewer :isActive.sync="modalFiles.active" :filesUrl="modalFiles.filesUrl"/>
    <ImageViewer ref="viewer"/>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'TabHeader',
  components: {
    FilesViewer: () => import('@/views/components/files-viewer/FilesViewer'),
    ImageViewer: () => import('@/views/components/image-viewer/ImageViewer')
  },
  props: {
    initData: { default: null, type: Object }
  },
  data () {
    return {
      modalFiles: {
        filesUrl: [],
        active: false
      }
    }
  },
  methods: {
    showFiles (filesUrl) {
      this.modalFiles.filesUrl = filesUrl
      this.modalFiles.active = true
    },

    showImages (images) {
      const items = _.cloneDeep(images)
      this.$refs.viewer.show(items)
    },

    next () {
      const currentActiveTab = this.$store.state.approvals.stcApproval.activeTab
      this.$store.commit('approvals/stcApproval/SET_ACTIVE_TAB', currentActiveTab + 1)
    }
  }
}
</script>
